var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "content" }, [
    _c(
      "div",
      { staticStyle: { "margin-bottom": "20px" } },
      [
        _c(
          "span",
          { staticStyle: { "margin-right": "30px", color: "#606266" } },
          [_vm._v("Chọn nguồn video ")]
        ),
        _c(
          "el-radio-group",
          {
            on: {
              change: function ($event) {
                return _vm.changeTypeMethod()
              },
            },
            model: {
              value: _vm.type,
              callback: function ($$v) {
                _vm.type = $$v
              },
              expression: "type",
            },
          },
          [
            _c("el-radio", { attrs: { label: "classType" } }, [_vm._v("Lớp")]),
            _c("el-radio", { attrs: { label: "schoolType" } }, [
              _vm._v("Trường"),
            ]),
            _c("el-radio", { attrs: { label: "OnekidsType" } }, [
              _vm._v("Onekids"),
            ]),
          ],
          1
        ),
      ],
      1
    ),
    _vm.src == ""
      ? _c(
          "div",
          { staticStyle: { "text-align": "center", "font-size": "20px" } },
          [_vm._v("\n    Không có dữ liệu\n  ")]
        )
      : _c("div", { staticClass: "table-content row-data" }, [
          _c("iframe", {
            attrs: {
              width: "100%",
              height: "920",
              allowfullscreen: "",
              src: _vm.src,
            },
          }),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }