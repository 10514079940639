import { render, staticRenderFns } from "./VideoParent.vue?vue&type=template&id=71925ff6&scoped=true"
import script from "./VideoParent.vue?vue&type=script&lang=js"
export * from "./VideoParent.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71925ff6",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/gitlab-runner/builds/SpMFxzSoQ/0/hero/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('71925ff6')) {
      api.createRecord('71925ff6', component.options)
    } else {
      api.reload('71925ff6', component.options)
    }
    module.hot.accept("./VideoParent.vue?vue&type=template&id=71925ff6&scoped=true", function () {
      api.rerender('71925ff6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/parent/video/VideoParent.vue"
export default component.exports